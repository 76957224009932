import * as React from "react"
import { FC, useContext, useEffect, useState } from "react"
import styled from "styled-components"
import { connectSearchBox } from "react-instantsearch-dom"
import algoliasearch from "algoliasearch"
import SEO from "../../../components/Seo"
import Layout from "../../../components/Layout"
import { filterByVisibility } from "../../util/productUtil"
import ProductFilterList from "../../components/product-list/ProductFilterList"
import { SearchContext } from "../../context/SearchProvider"
import { mapAlgoliaProduct } from "social-supermarket-model"
import { ImpactType, ProductType } from "social-supermarket-model"

const Container = styled.div`
  opacity: ${({ isSearching }) => (isSearching ? 0 : 1)};
  transition: all 0.2s ease-in-out;
  min-height: 500px;
`

interface Props {
  impacts: ImpactType[]
}

const InvisibleSearchBox = connectSearchBox(() => null)

let timer = null

const SearchPage: FC<Props> = ({ impacts }) => {
  const client = algoliasearch("BLA29WX2H4", "253a575f90629780f8b2001e0dfe8264")
  const index = client.initIndex("products")
  const [products, setProducts] = useState<ProductType[]>([])
  const { search } = useContext(SearchContext)
  const [isSearching, setIsSearching] = useState<boolean>(true)

  useEffect(() => {
    runSearch()
  }, [search])

  const runSearch = async () => {
    setIsSearching(true)
    clearTimeout(timer)
    timer = setTimeout(async () => {
      console.log("SEARCHING FOR " + search)
      const response = await index.search(search, {
        hitsPerPage: 800,
        clickAnalytics: true,
      })
      // @ts-ignore
      const mapped = response.hits.map(mapAlgoliaProduct)
      setProducts(mapped)
      setIsSearching(false)
    }, 500)
  }

  return (
    <Layout>
      <SEO title={"Search"} description={"search"} />
      <Container isSearching={isSearching}>
        <ProductFilterList
          products={filterByVisibility(products, "Marketplace")}
          collectionName={`Search Results`}
          impacts={impacts}
        />
      </Container>
    </Layout>
  )
}

export default SearchPage
